import React from 'react';
import BlogContent from '../content/blog/blogcontent';
import Layout from '../layout/layout';
import { graphql } from 'gatsby';


/**
 * TODO Find the newest entry in the list and render it
 * TODO Create a standard component for rendering blog page content.
 * call it blogpost because that will always look the same
 * BlogContent will have  asidebar on the left and then a blogpost.
 * the blog post template will only have the blogpost component
 * 
 */
class Blog extends React.Component {

  render() {
    const { data } = this.props;
    //console.log(data);
    //console.log(this.props);
    //console.log(this.props.location.pathname);
    //find the newest blog entry
    let node = undefined;
    for (let i=0; i < data.allMarkdownRemark.edges.length; i++) {
      //console.log(data.allMarkdownRemark.edges[i]);
      let e = data.allMarkdownRemark.edges[i].node;
      if (e.frontmatter.path.startsWith(this.props.location.pathname)) {
        //console.log('Blog with correct language');
        if (node === undefined) {
          node = e;
        }
      }
    }

    //console.log(node);

    return (
      <Layout location={this.props.location}>
        <BlogContent frontmatter={node.frontmatter} html={node.html} />
       
      </Layout>
    )
  }
}
export default Blog;

export const pageQuery = graphql`
    query {
      allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}) {
        edges {
          node {
            id
            excerpt(pruneLength: 250)
            html
            frontmatter {
              date
              path
              title
            }
          }
        }
      }
    }`